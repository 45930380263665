
/* font family */
@import url('https://fonts.cdnfonts.com/css/inter');
/* Import Open Sans font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  color: #333333;
}
.MuiCardActionArea-root{
  font-family: 'Open Sans', sans-serif;
  color: #333333 !important;
  font-size: 14px !important;
}
.MuiPaper-root{
  color: #333333 !important;
  font-size: 14px !important;
}
:root{
  --btn-color:#B8003E;
   --txt-color:#ffff;
}
.App {
  text-align: center;
}
/* paginations styles */
.mainPageCont {
  display: flex;
  justify-content: center;
}

.mainPageCont li {
  list-style-type: none;
  padding: 5px 13px;
  cursor: pointer; 
  margin-left: 1rem;
  font-size: 20px;
}
.mainPageCont>.previous,
 .next  {
  background:#ffff;
  /* padding:5px 15px 5px 10px!important; */
  border:1px solid var(--txt-color);
  border-radius: 50%;
  color: var(--btn-color);
  display: flex;
  align-items: center;
}
.mainPageCont>.previous a, .next a {
  display: flex;
}

.activeClassName {
  background-color: var(--btn-color);
  border-radius: 0.5rem!important;
}
.activeLinkClassName{
  color:#fff;
  background-color: var(--btn-color);
  border-radius: 0.5rem!important;
  /* font-size: 20px; */
}
.pageClassName {
  color:var(--footertextcolor);
  border-radius: 50%;
  border:1px solid var(--txt-color);
}

.gridContainer {
  background-color: #333333;
  border-radius: 15px;
  color: #FFFFFF;
  padding: 10px; /* Adjust padding as needed */
}

.MuiAccordionSummary-content{
 align-items: center;
justify-content: space-between;
}

.highlight {
  background-color: #f0f0f0; /* Adjust the color as needed */
  transition: background-color 0.3s ease;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: none !important;
}

.MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  border-radius: 8px !important;
 
}
